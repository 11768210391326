const questionData = {
  "en": {
    10: {
      title: 'How do I change my username?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: 'How do I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: 'How do I handle my subscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p>To change your username, click on your current username, located in the upper right corner of the screen. Select the "Settings" option from the drop-down menu.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_en.png')}"></p><p> In the "General" tab you can change your name.</p><p>Click "Save Changes" when you are done.</p>
      `,
    },
    11: {
      title: 'Can I change my email?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: 'How do I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: 'How do I handle my subscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_en.png')}"></p>
      <p>No, your email cannot be modified. If you are not an EducaMadrid user, to use another email you must unsubscribe with your current email, and re-register with the new email.</p>`,
    },
    12: {
      title: 'How do I change my password?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: 'How do I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: 'How do I handle my subscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `
      <p>Changing your password is very easy! you just have to click on your username, located in the upper right corner of the screen. Select the "Settings" option from the drop-down menu.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_en.png')}"></p>
      <p> In the "Change Password" tab you will need to enter your old password, the new password, and confirm the new password.</p><p>Click "Save Changes" when you are done.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>If you are an EducaMadrid user, this option is not available to you, and you must contact their support team to make this change.
      </p></div>
      </div>
      `,
    },
    13: {
      title: 'How do I change my language?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: 'How do I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: 'How do I handle my subscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p>
      There are two very easy ways to change the language in which the information is displayed.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_en.png')}">
      <p>In the upper right corner of the screen, next to your username you will find the current language of the website. You can choose one of the available languages ​​from the menu that will be displayed when you click on it.</p><p>The interface language will automatically change.</p>
      <hr class="help-divider">
      <p>Another way is from your account settings. Click on your current username, located in the upper right corner of the screen. Select the "Settings" option from the drop-down menu.
      </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_en.png')}"></p>
      <p>In the "General" tab you can choose one of the available menu languages.</p><p>Click "Save Changes" when you are done.
      </p>`,
    },
    14: {
      title: 'How do I handle my devices?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: 'How do I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: 'How do I handle my subscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p>To manage your devices, go to Account Settings by clicking on your username, located in the upper right corner of the screen.
      </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_en.png')}"></p>
      <p>In the "My Devices" tab you can see a list of those devices from which you have entered the platform.</p><p>Remember that you can have up to 5 active devices. Delete the ones you no longer use to be able to enter from a new device, by clicking on the red trash can icon.</p>`,
    },
    15: {
      title: 'How do I handle my subscriptions?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: 'How do I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: 'How do I handle my subscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p>Go to Account Settings by clicking on your username, located in the upper right corner of the screen.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_en.png')}"></p>
      <p>In the "My Subscriptions" tab you can see a list of all your history.</p><p>You can see more information about each record by clicking "Show".</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>If you are an EducaMadrid user, you do not have this option available.</p>
      </div>
      </div>`,
    },
    20: {
      title: 'How does Raíces integration works?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: 'How does Raíces integration works?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: 'What do I need before using Raíces integration?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: 'What do the states and actions mean in the validation table?',
          slug: 'what-do-the-states-and-actions-mean-in-the-validation-table',
        },
        {
          id: 24,
          question: 'Raíces Integration: User Manual',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Backend video tutorial',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>You will have access to the option of creating the structure of your school with the integration of Raíces only if you are a TIC coordinator or if you have the administrative role of a school in the Autonomous Community of Madrid.
      </p>
      </div>
      </div>
      <p>At Smile and Learn we have developed a new and improved process to create the initial structure of your school. In just a few steps you can have all the information of your center ready so that your students can start working without delay.</p><p>To carry out the procedure, you only need to have the files provided by Raíces and EducaMadrid at hand, and upload them to the platform. We will take care of everything else.</p><p>Do not worry because before making any changes, we will ask you to validate the information, so there will be no risk of losing any important data.</p>`,
    },
    21: {
      title: 'What do I need before using Raíces integration?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: 'How does Raíces integration works?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: 'What do I need before using Raíces integration?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: 'What do the states and actions mean in the validation table?',
          slug: 'what-do-the-states-and-actions-mean-in-the-validation-table',
        },
        {
          id: 24,
          question: 'Raíces Integration: User Manual',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Backend video tutorial',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>You will have access to this option only if you are a TIC coordinator or if you have the administrative role of a school in the Autonomous Community of Madrid.</p>
      </div>
      </div>
      <p>Before starting, make sure you obtain the Raíces and EducaMadrid data files, as we will explain below.</p>
      <p>The first thing you should do is enter to the official platforms to obtain the student lists:</p>
      <p><ul>
      <li>In <b>EducaMadrid</b> you must download <b>the complete file</b>, following the instructions:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Go to Administrator and, in the dropdown, choose "Control Panel"</li>
          <li>Click on "User Management"</li>
          <li>In the search engine, select type: Student. And click on "Search"</li>
          <li>Scroll down to the end of the columns containing the information and click on the left, where it says "download .csv".<span style="color:red;"> It is important that you do not modify this document at all.</span></li>
        </ol>
      </p>

      <p><ul>
      <li>On the <b>Raíces</b> platform you will have to generate the file with the students of your center:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Go to Student Body</li>
          <li>Click on Center Students</li>
          <li>Use the filters if necessary (to choose the academic year, etc.)</li>
          <li>In the data export section, select the Excel format (.xls)</li>
          <li>Select the following columns: <b>Student, First surname, Second surname, Name, Date of birth, Course, Unit, ID number. Student</b></li>
        </ol>
      </p>

      <div class="alert alert-warning">
      <div class="alert-heading">Attention</div>
      <div class="alert-body">
      <p>If you do not have access to Raíces, you must request the document from the secretary or the administrative team and ask them not to modify it once the file is downloaded.</p></div>
      </div>

      <p>Once you have these two files, you are ready to generate your structure quickly and easily.</p>
      <p>Remember that, if you have any questions, you can write to us at  support@smileandlearn.com </p>`,
    },
    22: {
      title: 'What do the states and actions mean in the validation table?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: 'How does Raíces integration works?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: 'What do I need before using Raíces integration?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: 'What do the states and actions mean in the validation table?',
          slug: 'what-do-the-states-and-actions-mean-in-the-validation-table',
        },
        {
          id: 24,
          question: 'Raíces Integration: User Manual',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Backend video tutorial',
          slug: 'videotutorial',
        }
      ],
      content: `<p>In the data validation table, you will find the "Status" and "Action" columns. </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_en.png')}"></p>
      <p>The first column indicates if the record has the complete information to be saved. In the event that the status appears in red with a "KO", you must go back, complete the information in the corresponding file and re-upload it.</p>
      <p>The second column indicates the action that the system will carry out once you have confirmed that the information is correct. You can receive two messages:</p>
      <ul>
      <li><b>CREATE:</b> The system detects that it is a new record, and therefore it will create it.</li>
      <li><b>UPDATE:</b> The system has found a record with the same identifier in the database, so it will overwrite the record with the new information provided.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>You will have access to this option only if you are a TIC coordinator or if you have the administrative role of a school in the Community of Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Raíces Integration: User Manual',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: 'How does Raíces integration works?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: 'What do I need before using Raíces integration?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: 'What do the states and actions mean in the validation table?',
          slug: 'what-do-the-states-and-actions-mean-in-the-validation-table',
        },
        {
          id: 24,
          question: 'Raíces Integration: User Manual',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Backend video tutorial',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>You will have access to this option only if you are a TIC coordinator or if you have the administrative role of a school in the Community of Madrid.</p>
      </div>
      </div>
      <p>In this manual we are going to explain step by step the procedure to follow to generate the structure of your center in Smile and Learn. How are we going to do it? In a very simple way, retrieving the center information provided by Raíces and emails from EducaMadrid.</p>
      <p>The first thing you should do is enter to the official platforms to obtain the student lists:</p>
      <p><ul>
      <li>In <b>EducaMadrid</b> you must download <b>the complete file</b>, following the instructions:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Go to Administrator and, in the dropdown, choose "Control Panel"</li>
          <li>Click on "User Management"</li>
          <li>In the search engine, select type: Student. And click on "Search"</li>
          <li>Scroll down to the end of the columns containing the information and click on the left, where it says "download .csv".<span style="color:red;"> It is important that you do not modify this document at all.</span></li>
        </ol>
      </p>
      <p><ul>
      <p><ul>
      <li>On the <b>Raíces</b> platform you will have to generate the file with the students of your center:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Go to Student Body</li>
          <li>Click on Center Students</li>
          <li>Use the filters if necessary (to choose the academic year, etc.)</li>
          <li>In the data export section, select the Excel format (.xls)</li>
          <li>Select the following columns: <b>Student, First surname, Second surname, Name, Date of birth, Course, Unit, ID number. Student</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Attention</div>
      <div class="alert-body">
      <p>If you do not have access to Raíces, you must request the document from the secretary or the administrative team and ask them not to modify it once the file is downloaded.</p>
      </div>
      </div>
      
      <p>Once you have these two files, you are ready to generate your structure quickly and easily. You only have to follow the steps that we explain below:</p><p>
      1. Access Smile and Learn and log in with your full EducaMadrid email and password.
      </p>
      <p>2. Once inside you will be able to see an administration panel with statistics of your educational center. In the left side menu you will find the “Raíces” section.
      </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_en.png')}"></p>
      <p>3. Clicking on the “Raíces” section, a document upload window will appear. Drag the files that you have previously generated from Raíces and EducaMadrid to their corresponding place (blue squares), or click on them to search for them in the folders on your device. Remember that the Raíces file has an <b>.xls</b> extension, while the EducaMadrid file has an <b>.csv</b> extension.</p>

      <p style="color:red">It is very important that the documents are those provided by Raíces and EducaMadrid <b>without alterations of any kind</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_en.png')}"></p>
      <p>4. Once the files have been selected, click on “Continue”. The information loading process will start. This action may take a few minutes while the data is processed.</p>
      <p>The system will be able to detect discrepancies between the Smile and Learn database and the Raíces and EducaMadrid official documents that you have selected, and will provide a complete list of students indicating the status of the registration and the actions to be carried out on it. Once the upload is complete, you must verify the processed information and thus confirm that everything is correct.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_en.png')}"></p>
      <p>5. If the information shown in the verification is not correct, you can go back to the previous step and upload the files again with the correct information.</p>
      <p>6. If all the data is correct and you want to continue, you must check the confirmation box and then click on the “finish” button. This process may take a few minutes.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_en.png')}"></p>
      `,
    },
    25: {
      title: 'Backend video tutorial',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: 'How does Raíces integration works?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: 'What do I need before using Raíces integration?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: 'What do the states and actions mean in the validation table?',
          slug: 'what-do-the-states-and-actions-mean-in-the-validation-table',
        },
        {
          id: 24,
          question: 'Raíces Integration: User Manual',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Backend video tutorial',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">Hello! This is the video tutorial to learn how to use the platform for creating and updating structures for schools associated with the agreement between Smile and Learn and EducaMadrid.
      </p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: 'How do I create a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: 'How do I asign teachers to a group?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: 'How do I asign students to a group?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: 'How do I download a list of my groups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Enter the Groups section located in the left side menu. Once inside, click on the green button with the plus symbol.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_en.png')}""></p>
      <p>A form will open where you must indicate the name of the group. Then click "Add".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_form_en.png')}""></p>
      <p>Your group will be added to the bottom of the list. You can edit it by clicking on its name, or by selecting the "Edit" option from the dropdown menu in the "action" column.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>`,
    },
    31: {
      title: 'How can I see and edit a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: 'How do I asign teachers to a group?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: 'How do I asign students to a group?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: 'How do I download a list of my groups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Enter the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently own.</p>
      <p>In the "Action" column you have the option to see the details of that group, edit it or delete it.</p>
      <p>You can also click on the group name to go to the details view.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>
      <h4>Details</h4><p>In this view you will find, next to the group information, a list with the children that the group currently owns. You can unlink them by clicking the red icon with the crossed out chain. You can also add new children by clicking the link in the upper corner of the card.</p><p>You can also manage teacher accounts in the same way.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_en.png')}""></p>
      <h4>Edit Group</h4>
      <p>If you enter the editing view, you will see a first tab where you can change the group avatar and its name. Don't forget to click "Save Changes" when you're done.</p>
      <p>The next two tabs will help you manage your teachers and children.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_en.png')}"></p>`,
    },
    32: {
      title: 'How can I delete the groups I no longer need?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: 'How do I asign teachers to a group?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: 'How do I asign students to a group?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: 'How do I download a list of my groups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Enter the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently have.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_en.png')}"></p>
      <p>In the "Action" column, click on the dropdown and select the "Delete" option.</p>
      <p>You can also check the boxes next to the group avatar, and then on the red button with the trash can select the option "Delete Selection".</p>
      <p>Note that deleting a group does NOT delete the children. These will be transferred to a special group called "Children without a group".</p>`,
    },
    33: {
      title: 'How do I asign teachers to a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: 'How do I asign teachers to a group?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: 'How do I asign students to a group?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: 'How do I download a list of my groups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Enter the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click the group name to go to the details view, and then click the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_en.png')}""></p>
      <p>Once in Edit view, select the Assigned Teachers tab.</p>
      <p>Select the emails of all the teachers you need to link to that group. Don't forget to click "Save Changes" when you're done.</p>`,
    },
    34: {
      title: 'How do I asign students to a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: 'How do I asign teachers to a group?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: 'How do I asign students to a group?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: 'How do I download a list of my groups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Enter the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently have.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click the group name to go to the details view, and then click the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_dreamer_en.png')}""></p>
      <p>Once in Edit view, select the Children tab.</p>
      <p>Select the names of all the children you want to link to that group. Don't forget to click "Save Changes" when you're done.</p>`,
    },
    35: {
      title: 'How do I download a list of my groups?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: 'How do I asign teachers to a group?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: 'How do I asign students to a group?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: 'How do I download a list of my groups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Enter the Groups section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <p>Click on the blue button "Export all records", with the icon of the Excel document, and the file will automatically be downloaded to your device.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_en.png')}""></p>`,
    },
    41: {
      title: 'How do I create a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: 'How can I see and edit a dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: 'How can I delete the dreamer I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: 'How do I asign groups to a dreamer?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: 'How do I asign an email to a dreamer?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Enter the Children section located in the left side menu. Once inside, click on the green button with the plus symbol.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_en.png')}""></p>
      <p>A form will open where you must indicate the name of the child, his date of birth, student license (if he has one), group to which he belongs and registration number (optional). In addition, you have the option to add a family email, to share the child with a family member (who must have an active subscription to Smile and Learn).</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_en.png')}""></p>
      <p>Then click "Add", and your child will be added to the bottom of the list. You can edit it by clicking on its name, or by selecting the "Edit" option from the dropdown menu in the "action" column.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      `,
    },
    42: {
      title: 'How can I see and edit a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: 'How can I see and edit a dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: 'How can I delete the dreamer I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: 'How do I asign groups to a dreamer?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: 'How do I asign an email to a dreamer?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Enter the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <p>In the "Action" column you have the option to view the details of that child, edit it or delete it.</p>
      <p>You can also click on the child's name to go to the details view.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      <h4>Details</h4>
      <p>In this view you will find, next to the child's information, a list with the groups that they currently have. You can unlink them by clicking the red icon with the crossed out chain. You can also add new groups by clicking on the link located in the upper corner of the card.</p><p>Also, you can manage student licenses in the same way.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_en.png')}""></p>
      <h4>Edit Child</h4>
      <p>If you enter the editing view, you will see a first tab where you can change the child's avatar, name, date of birth and registration number. Don't forget to click "Save Changes" when you're done.</p>
      <p>The next two tabs will help you manage the student license and groups.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_en.png')}"></p>
      `,
    },
    43: {
      title: 'How can I delete the dreamer I no longer need?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: 'How can I see and edit a dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: 'How can I delete the dreamer I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: 'How do I asign groups to a dreamer?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: 'How do I asign an email to a dreamer?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Enter the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <h4>Deactivate a child</h4>
      <p>In the "Action" column, click on the dropdown of the child to delete and select the "Delete" option. When performing this action, the selected children will be deleted, and can be recovered within a period of 15 days. To do this, you must send an email to support@smileandlearn.com indicating their names.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_en.png')}""></p>
      <h4>Mass deactivate children</h4>
      <p>You can also remove multiple children by checking the checkboxes next to each child's avatar, then on the red button with the trash can select the option "Deactivate selected children".</p>
      <p>By performing this action the selected children will be deleted, and can be recovered within 15 days. To do this, you must send an email to support@smileandlearn.com indicating their names.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_en.png')}""></p>
      <h4>Delete children en masse with an Excel file</h4>
      <p>Another way is to unsubscribe children en masse with an Excel file. You can find this option on the red button with the trash can, selecting the option "Unsubscribe children with Excel".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_en.png')}""></p>
      <p> The steps to follow, a downloadable example template, and the document upload panel will be displayed.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_excel_en.png')}""></p>
      <h4>Delete all children</h4>
      <p>If you made a mistake when uploading the structure of your center, you have the option to delete all the children with a single button. It is located on the red button with the trash can, under the name "Delete all children". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_en.png')}""></p>
      <hr class="help-divider">

      <h3>What is the difference between "deactivate", "unsubscribe" and "delete" a child?</h3>

      <p><b>Deactivate selected children</b>: the student will continue to be linked to the school, but the teacher will not see them in their group. It can be reactivated at any time.</p>
      <p>To do this, you have a period of 15 days to send an email to support@smileandlearn.com indicating their names and your user account.</p>
      <p><b>Unsubscribe children with Excel</b>: the student will be completely disconnected from the school, but will be able to continue using Smile and Learn with their EducaMadrid email and password. However, their subscription will become limited.</p>
      <p><b>Delete all records</b>: All children from the school are completely deleted along with their license and progress. There is no going back.</p>
      <hr class="help-divider">

      <h3>What happens if an inactive child logs in?</h3>
      <p>The child will be able to access Smile and Learn without restrictions, but the profile linked to the school will not appear. However, he can create a new student profile, something to avoid if you want to reactivate it in the near future.</p>
      <h3>What happens if a dropped child logs in?</h3>
      <p>The child will be able to access Smile and Learn with restrictions (such as if he doesn't have an active paid license) and his school profile will be completely deleted.</p>
      <h3>What happens if a deleted child logs in?</h3>
      <p>The child will not be able to access Smile and Learn.</p>
      `,
    },
    44: {
      title: 'How do I asign groups to a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: 'How can I see and edit a dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: 'How can I delete the dreamer I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: 'How do I asign groups to a dreamer?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: 'How do I asign an email to a dreamer?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Enter the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      <p>n the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click the child's name to go to the details view, and then click the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_en.png')}""></p>
      <p>Once in Edit view, select the Groups tab.</p>
      <p>Select the names of all the groups where you want that child to participate. Don't forget to click "Save Changes" when you're done.</p>`,
    },
    45: {
      title: 'How do I asign an email to a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: 'How can I see and edit a dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: 'How can I delete the dreamer I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: 'How do I asign groups to a dreamer?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: 'How do I asign an email to a dreamer?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Enter the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click on the child's name to go to the details view, and then click on the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_en.png')}""></p>
      <p>Once in the Edit view, select the Student License tab.</p>
      <p>Select the email that corresponds to that child. Don't forget to click "Save Changes" when you're done.</p>`,
    },
    46: {
      title: 'How do I download a list of my dreamers?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: 'How can I see and edit a dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: 'How can I delete the dreamer I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: 'How do I asign groups to a dreamer?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: 'How do I asign an email to a dreamer?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Enter the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <p>Click on the blue button "Export all records", with the icon of the Excel document, and the file will automatically be downloaded to your device.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_export_en.png')}""></p>`,
    },
    51: {
      title: 'How do I delete my school structure?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: 'How do I delete my school structure?',
          slug: 'how-do-i-delete-school-structure',
        },
      ],
      content: `<p>In the left side menu, look for the "School Structure" section and click on "Handle".</p>
      <p>Then fill in the confirmation text and click delete. Remember that the children and their progress will not be deleted, only the groups of the center will be deleted, and the children will be sent to the "Children without a group" group.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_en.png')}""></p>`,
    },
  },
  "es": {
    10: {
      title: '¿Cómo cambio mi nombre de usuario?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p>Para cambiar tu nombre de usuario haz clic en tu nombre de usuario actual, ubicado en la esquina superior derecha de la pantalla. Selecciona la opción "Ajustes" del menú desplegable.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_es.png')}"></p><p> En la pestaña "General" puedes cambiar tu nombre.</p><p>Haz clic en "Guardar cambios" cuando hayas terminado.</p>
      `,
    },
    11: {
      title: '¿Puedo cambiar mi email?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_es.png')}"></p>
      <p>No, tu email no puede ser modificado. Si no eres usuario de EducaMadrid, para usar otro correo electrónico deberás darte de baja con tu correo actual, y volver a registrarte con el nuevo email.</p>`,
    },
    12: {
      title: '¿Cómo cambio mi contraseña?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `
      <p>¡Cambiar tu contraseña es muy fácil! solo debes hacer clic en tu nombre de usuario, ubicado en la esquina superior derecha de la pantalla. Selecciona la opción "Ajustes" del menú desplegable.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_es.png')}"></p>
      <p> En la pestaña "Cambiar contraseña" deberás ingresar tu contraseña anterior, la nueva contraseña, y confirmar la nueva contraseña.</p><p>Haz clic en "Guardar cambios" cuando hayas terminado.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Si eres un usuario de EducaMadrid, no tienes disponible esta opción, y deberás ponerte en contacto con su equipo de soporte para realizar este cambio.</p></div>
      </div>
      `,
    },
    13: {
      title: '¿Cómo cambio el idioma?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p>Hay dos maneras muy sencillas de cambiar el idioma en que se muestra la información.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_es.png')}">
      <p>En la esquina superior derecha de la pantalla, junto a tu nombre de usuario encontrarás el idioma actual del sitio web. Puedes elegir uno de los idiomas disponibles del menú que se desplegará al pinchar sobre él.</p><p>Automaticamente el idioma de la interfaz cambiará.</p>
      <hr class="help-divider">
      <p>Otra manera es desde los ajustes de tu cuenta. Haz clic en tu nombre de usuario actual, ubicado en la esquina superior derecha de la pantalla. Selecciona la opción "Ajustes" del menú desplegable.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_es.png')}"></p>
      <p> En la pestaña "General" puedes elegir uno de los idiomas disponibles del menú.</p><p>Haz clic en "Guardar cambios" cuando hayas terminado.</p>`,
    },
    14: {
      title: '¿Cómo puedo gestionar mis dispositivos?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p>Para gestionar tus dispositivos, dirígete a Ajustes de Cuenta haciendo clic en tu nombre de usuario, ubicado en la esquina superior derecha de la pantalla.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_es.png')}"></p>
      <p> En la pestaña "Mis Dispositivos" podrás ver un listado de aquellos dispositivos desde los cuales hayas ingresado a la plataforma.</p><p>Recuerda que puedes tener hasta 5 dispositivos activos. Elimina los que ya no utilices para poder ingresar desde un dispositivo nuevo, haciendo clic en el icono rojo de la papelera.</p>`,
    },
    15: {
      title: '¿Cómo puedo gestionar mis suscripciones?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
      content: `<p>Dirígete a Ajustes de Cuenta haciendo clic en tu nombre de usuario, ubicado en la esquina superior derecha de la pantalla.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_es.png')}"></p>
      <p>En la pestaña "Mis Suscripciones" podrás ver un listado de todo tu historial.</p><p>Puedes ver más información de cada registro haciendo clic en "Mostrar".</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Si eres un usuario de EducaMadrid, no tienes disponible esta opción.</p>
      </div>
      </div>`,
    },
    20: {
      title: '¿Cómo funciona la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a la opción de crear la estructura de tu colegio con la integración de Raíces solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>En Smile and Learn hemos desarrollado un nuevo y mejorado proceso para crear la estructura inicial de tu colegio. En tan solo unos pocos pasos podrás tener toda la información de tu centro preparada para que tu alumnado pueda empezar a trabajar sin demoras.</p><p>Para llevar a cabo el procedimiento solo necesitas tener a mano los ficheros que proporcionan Raíces y EducaMadrid, y subirlos a la plataforma. Nosotros nos encargaremos de todo lo demás.</p><p>No te preocupes ya que antes de realizar cualquier cambio, te pediremos que valides la información, por lo que no habrá riesgos de perder ningún dato importante.</p>`,
    },
    21: {
      title: '¿Qué necesito antes de usar la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>Antes de comenzar asegúrate de obtener los ficheros de datos de Raíces y EducaMadrid tal como te explicaremos a continuación.</p>
      <p>Lo primero que debes hacer es ingresar a las plataformas oficiales para obtener los listados del alumnado:</p>
      <p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>, siguiendo las instrucciones:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
          <li>Pincha en "Gestión de Usuarios"</li>
          <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
          <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
  
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Alumnado</li>
          <li>Pincha en Alumnado del centro</li>
          <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
          <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
          <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
  
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
  
      <p>Una vez tengas estos dos archivos, estarás listo para generar tu estructura de forma rápida y sencilla.</p>
      <p>Recuerda que, ante cualquier duda, puedes escribirnos a support@smileandlearn.com </p>
  
  
      `,
    },
    22: {
      title: '¿Qué significan los estados y acciones en la tabla de validación de información?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<p>En la tabla de validación de la información, encontrarás las columnas "Estado" y "Acción". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>La primera columna indica si el registro cuenta con la información completa para poder ser guardado. En el caso de que el estado aparezca en color rojo con un "KO" deberás volver atrás, completar la información en el fichero correspondiente y volver a subirlo.</p>
      <p>La segunda columna, indica la acción que llevará a cabo el sistema una vez hayas confirmado que la información es correcta. Puedes recibir dos mensajes:</p>
      <ul>
      <li><b>CREAR:</b> El sistema detecta que es un nuevo registro, y por lo tanto lo creará.</li>
      <li><b>ACTUALIZAR:</b> El sistema ha encontrado un registro con el mismo identificador en la base de datos, por lo que sobreescribirá el registro con la nueva información proporcionada.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Integración con Raíces: Manual de Uso',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      <p>En este manual vamos a explicarte paso por paso el procedimiento a seguir para generar la estructura de tu centro en Smile and Learn. ¿Cómo vamos a hacerlo? De una manera muy sencilla, recuperando la información de centro que te proporciona Raíces y los correos electrónicos de EducaMadrid.</p>
      <p>Lo primero que debes hacer es conseguir dos ficheros de las plataformas oficiales:</p><p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
        <li>Pincha en "Gestión de Usuarios"</li>
        <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
        <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Alumnado</li>
        <li>Pincha en Alumnado del centro</li>
        <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
        <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
        <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
      
      <p>Una vez tengas estos dos ficheros, estarás listo para generar tu estructura de forma rápida y sencilla. Solo tendrás que seguir los pasos que te explicamos a continuación:</p><p>
      1. Accede a Smile and Learn e inicia sesión con tu correo completo y contraseña de EducaMadrid. 
      </p>
      <p>2. Una vez dentro podrás ver un panel de administración con estadísticas de tu centro educativo. En el menú lateral izquierdo encontrarás la sección “Raíces”.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_es.png')}"></p>
      <p>3. Pulsando sobre la sección “Raíces”, aparecerá una ventana de carga de documentos. Arrastra los ficheros que previamente has generado de Raíces y EducaMadrid a su lugar correspondiente (cuadros azules), o haz clic sobre ellos para buscarlos en las carpetas de tu dispositivo. Recuerda que el fichero de Raíces tiene una extensión <b>.xls</b>, mientras que el de EducaMadrid tiene una extensión <b>.csv</b></p>
  
      <p style="color:red">Es muy importante que los documentos sean los que hayan proporcionado Raíces y EducaMadrid <b>sin alteraciones de ningún tipo</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_es.png')}"></p>
      <p>4. Una vez seleccionados los ficheros, haz clic en “Continuar”. Se iniciará el proceso de carga de la información. Esta acción puede durar unos minutos mientras se procesan los datos.</p>
      <p>El sistema será capaz de detectar discrepancias entre la base de datos de Smile and Learn y los documentos oficiales de Raíces y EducaMadrid que has seleccionado, y proporcionará un listado completo de estudiantes indicando el estado del registro y las acciones a realizar sobre el mismo. Una vez finalizada la carga, deberás verificar la información procesada y así confirmar que todo es correcto.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>5. Si la información que se muestra en la verificación no es correcta, puedes volver al paso anterior y cargar de nuevo los ficheros con la información correcta.</p>
      <p>6. Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón “finalizar”. Este proceso puede durar unos minutos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_es.png')}"></p>
      `,
    },
    25: {
      title: 'Videotutorial Backend',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">¡Hola! Este es el videotutorial para aprender a utilizar la plataforma de creación y actualización de estructuras de los colegios asociados al convenio entre Smile and Learn y EducaMadrid.</p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: '¿Cómo creo un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: '¿Cómo descargo mi listado actual de grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, haz clic en el botón verde con el símbolo de suma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_es.png')}""></p>
      <p> Se abrirá un formulario donde deberás indicar el nombre del grupo. Luego haz clic en "Añadir".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_form_es.png')}""></p>
      <p>Tu grupo será añadido al final del listado. Puedes editarlo haciendo clic en su nombre, o seleccionando la opción "Editar" del menú desplegable de la columna "acción".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>`,
    },
    31: {
      title: '¿Cómo puedo ver y editar un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: '¿Cómo descargo mi listado actual de grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p>En la columna "Acción" tienes la opción de ver los detalles de ese grupo, editarlo o eliminarlo.</p>
      <p>También puedes hacer clic en el nombre del grupo para ir a la vista de detalles.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>
      <h4>Detalles</h4><p>En esta vista encontrarás, junto a la información del grupo, un listado con los niños que posee actualmente el grupo. Puedes desvincularlos haciendo clic en el icono rojo con la cadena tachada. También puedes agregar nuevos niños haciendo clic en el link ubicado en la esquina superior de la tarjeta.</p><p>Asimismo, podrás gestionar las cuentas de profesores de la misma manera.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_es.png')}""></p>
      <h4>Editar Grupo</h4>
      <p>Si entras a la vista de edición, veras una primera pestaña donde puedes cambiar el avatar del grupo y su nombre. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>
      <p>Las dos pestañas siguientes te ayudarán a gestionar tus profesores y niños.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_es.png')}"></p>`,
    },
    32: {
      title: '¿Cómo puedo borrar los grupos que ya no necesito?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: '¿Cómo descargo mi listado actual de grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_es.png')}"></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Eliminar".</p>
      <p>También puedes marcar las casillas ubicadas junto al avatar del grupo, y luego en el botón rojo con la papelera seleccionar la opción "Eliminar Selección".</p>
      <p>Ten en cuenta que eliminar un grupo NO elimina los niños. Éstos serán trasladados a un grupo especial denominado "Niños sin grupo".</p>`,
    },
    33: {
      title: '¿Cómo asigno profesores a un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: '¿Cómo descargo mi listado actual de grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del grupo para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Profesores Asignados.</p>
      <p>Selecciona los correos electrónicos de todos los profesores que necesites vincular a ese grupo. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    34: {
      title: '¿Cómo añado alumnos a un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: '¿Cómo descargo mi listado actual de grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del grupo para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_dreamer_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Niños.</p>
      <p>Selecciona los nombres de todos los niños que quieras vincular a ese grupo. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    35: {
      title: '¿Cómo descargo mi listado actual de grupos?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: '¿Cómo descargo mi listado actual de grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p>Pincha en el botón azul "Exportar todos los registros", con el icono del documento de Excel, y automáticamente se descargá el fichero a tu dispositivo.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>`,
    },
    41: {
      title: '¿Cómo creo un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electrónico a un niño?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: '¿Cómo descargo mi listado actual de niños?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, haz clic en el botón verde con el símbolo de suma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_es.png')}""></p>
      <p>Se abrirá un formulario donde deberás indicar el nombre del niño, su fecha de nacimiento, licencia de estudiante (si es que posee una), grupo al que pertenece y número de registro (opcional). Además, tienes la opción de añadir un email familiar, para compartir el niño con un miembro de la familia (que deberá tener una suscripción activa en Smile and Learn). </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_es.png')}""></p>
      <p>Luego haz clic en "Añadir", y tu niño será añadido al final del listado. Puedes editarlo haciendo clic en su nombre, o seleccionando la opción "Editar" del menú desplegable de la columna "acción".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      `,
    },
    42: {
      title: '¿Cómo puedo ver y editar un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electrónico a un niño?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: '¿Cómo descargo mi listado actual de niños?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p>En la columna "Acción" tienes la opción de ver los detalles de ese niño, editarlo o eliminarlo.</p>
      <p>También puedes hacer clic en el nombre del niño para ir a la vista de detalles.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      <h4>Detalles</h4>
      <p>En esta vista encontrarás, junto a la información del niño, un listado con los grupos que posee actualmente. Puedes desvincularlos haciendo clic en el icono rojo con la cadena tachada. También puedes agregar nuevos grupos haciendo clic en el link ubicado en la esquina superior de la tarjeta.</p><p>Asimismo, podrás gestionar las licencias de estudiante de la misma manera.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_es.png')}""></p>
      <h4>Editar Niño</h4>
      <p>Si entras a la vista de edición, verás una primera pestaña donde puedes cambiar el avatar del niño, su nombre, fecha de nacimiento y número de registro. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>
      <p>Las dos pestañas siguientes te ayudarán a gestionar la licencia de estudiante y grupos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_es.png')}"></p>
      `,
    },
    43: {
      title: '¿Cómo puedo borrar los niños que ya no necesito?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electrónico a un niño?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: '¿Cómo descargo mi listado actual de niños?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <h4>Desactivar un niño</h4>
      <p>En la columna "Acción", pincha en el desplegable del niño a eliminar y selecciona la opción "Eliminar". Al realizar esta acción los niños seleccionados se eliminarán, pudiendo ser recuperados en un plazo de 15 días. Para ello, deberás enviar un correo a support@smileandlearn.com indicando sus nombres.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_es.png')}""></p>
      <h4>Desactivar niños de manera masiva</h4>
      <p>También puedes eliminar varios niños marcando las casillas de verificación ubicadas junto al avatar de cada niño, y luego en el botón rojo con la papelera seleccionar la opción "Desactivar niños seleccionados".</p>
      <p>Al realizar esta acción los niños seleccionados se eliminarán, pudiendo ser recuperados en un plazo de 15 días. Para ello, deberás enviar un correo a support@smileandlearn.com indicando sus nombres.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_es.png')}""></p>
      <h4>Borrar niños de manera masiva con un fichero de Excel</h4>
      <p>Otra manera es dar de baja niños de forma masiva con un fichero de Excel. Esta opción la puedes encontrar en el botón rojo con la papelera, seleccionando la opción "Dar de baja niños con Excel".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_es.png')}""></p>
      <p> Se mostrarán los pasos a seguir, una plantilla de ejemplo descargable, y el panel de carga del documento.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_excel_es.png')}""></p>
      <h4>Eliminar todos los niños</h4>
      <p>Si al subir la estructura de tu centro has cometido algún error, tienes la opción de eliminar todos los niños con un solo botón. El mismo está ubicado en el botón rojo con la papelera, bajo el nombre de "Eliminar todos los niños". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_es.png')}""></p>
      <hr class="help-divider">
  
      <h3>¿Cuál es la diferencia entre "desactivar", "dar de baja" y "eliminar" un niño?</h3>
  
      <p><b>Desactivar niños seleccionados</b>: el alumno seguirá vinculado al colegio, pero el docente no lo verá en su grupo. Se puede reactivar en cualquier momento.</p>
      <p>Para ello posees un plazo de 15 días para enviar un correo a support@smileandlearn.com indicando sus nombres y tu cuenta de usuario.</p>
      <p><b>Dar de baja niños con Excel</b>: el alumno se desvinculará por completo del colegio, pero podrá seguir utilizando Smile and Learn con su email y contraseña de EducaMadrid. Sin embargo, su suscripción pasará a ser limitada.</p>
      <p><b>Eliminar todos los registros</b>: se eliminan por completo todos los niños del colegio junto con su licencia y progreso. No tiene vuelta atrás.</p>
      <hr class="help-divider">
  
      <h3>¿Qué sucede si inicia sesión un niño inactivo?</h3>
      <p>Podrá acceder a Smile and Learn sin restricciones, pero no le aparecerá el perfil vinculado al colegio. Además, puede crearse un nuevo perfil de alumno, algo a evitar si queremos reactivarlo en un futuro cercano.</p>
      <h3>¿Qué sucede si inicia sesión un niño dado de baja?</h3>
      <p>Podrá acceder a Smile and Learn con restricciones (como si no tuviera una licencia de pago activa) y su perfil del colegio estará totalmente borrado.</p>
      <h3>¿Qué sucede si inicia sesión un niño eliminado?</h3>
      <p>No podrá acceder a Smile and Learn.</p>
      `,
    },
    44: {
      title: '¿Cómo asigno grupos a un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electrónico a un niño?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: '¿Cómo descargo mi listado actual de niños?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del niño para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Grupos.</p>
      <p>Selecciona los nombres de todos los grupos donde quieras que participe ese niño. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    45: {
      title: '¿Cómo asigno un correo electrónico o licencia de estudiante a un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electrónico a un niño?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: '¿Cómo descargo mi listado actual de niños?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del niño para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Licencia de estudiante.</p>
      <p>Selecciona el correo que corresponda a ese niño. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    46: {
      title: '¿Cómo descargo mi listado actual de niños?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electrónico a un niño?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: '¿Cómo descargo mi listado actual de niños?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p>Pincha en el botón azul "Exportar todos los registros", con el icono del documento de Excel, y automáticamente se descargá el fichero a tu dispositivo.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_export_es.png')}""></p>`,
    },
    51: {
      title: '¿Cómo elimino la estructura de mi colegio?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: '¿Cómo elimino la estructura de mi colegio?',
          slug: 'how-do-i-delete-school-structure',
        },
      ],
      content: `<p>En el menú lateral izquierzo, busca la sección de "Estructura de Colegio" y haz clic en "Gestionar".</p>
      <p>A continuación, completa el texto de confirmación y haz clic en eliminar. Recuerda que los niños y su progreso no se eliminarán, solamente se borrarán los grupos del centro, y los niños serán enviados al grupo de "Niños sin grupo".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_es.png')}""></p>`,
    },
  }
}

export default questionData